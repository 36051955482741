<template>
  <div class="">
    <form
      class="add-form"
      ref="profile_form"
      @submit.prevent="handleSubmit"
      @keydown.enter.prevent="() => {}"
    >
      <div class="add-form-inner">
        <div class="form-group-wrapper">
          <!-- icon -->
          <div class="form-group-wrapper-item full">
            <image-selector
              :label="$t('SPOT_CATEGORIES.SPOT_FORM_TYPE')"
              :defaultImage="spotCategory.icon"
              ressource_name="products"
              :ressource_id="spotCategory.id ? spotCategory.id : 0"
              field="icon"
              @imageChanged="
                (file_url) => {
                  spotCategory.icon = file_url;
                  onFormChanged();
                }
              "
            />
            <validation-error :errors="apiValidationErrors.icon" />
          </div>

          <!-- name -->
          <div class="form-group-wrapper-item">
            <base-input
              :label="`${$t('COMMON.NAME')} (*)`"
              v-model="spotCategory.name"
              @change="
                () => {
                  onFormChanged();
                }
              "
            />
            <validation-error :errors="apiValidationErrors.name" />
          </div>

          <div class="form-group-wrapper-item">
            <base-input :label="`${$t('SPOT_CATEGORIES.SPOT_FORM_TYPE')}`">
              <el-select
                v-model="spotCategory.form_type"
                @change="onFormChanged"
              >
                <el-option
                  v-for="(value, key) in FORM_TYPES"
                  :key="key"
                  :value="value"
                  :label="$t(`SPOT_CATEGORIES.SPOT_FORM_TYPE_${value}`)"
                />
              </el-select>
            </base-input>
            <validation-error :errors="apiValidationErrors.form_type" />
          </div>

          <div class="form-group-wrapper-item">
            <base-input :label="`${$t('SPOT_CATEGORIES.BOOKING_FORM_TYPE')}`">
              <el-select
                v-model="spotCategory.booking_form_type"
                @change="onFormChanged"
              >
                <el-option
                  v-for="value in bookingFormTypeOptions"
                  :key="value"
                  :value="value"
                  :label="$t(`SPOT_CATEGORIES.BOOKING_FORM_TYPE_${value}`)"
                />
              </el-select>
            </base-input>
            <validation-error :errors="apiValidationErrors.booking_form_type" />
          </div>

          <!-- color -->
          <!-- <div class="form-group-wrapper-item">
            <base-input
              input-classes="form-control-alternative"
              :label="$t('COMMON.COLOR')"
              v-model="spotCategory.color"
              type="color"
              @change="
                () => {
                  onFormChanged();
                }
              "
            />
            <validation-error :errors="apiValidationErrors.color" />
          </div> -->

          <!-- Excerpt -->
          <div class="form-group-wrapper-item full">
            <base-input
              :label="$t('COMMON.COMMENT')"
              :placeholder="$t('COMMON.COMMENT')"
            >
              <html-editor
                v-model="spotCategory.excerpt"
                @change="onFormChanged()"
              >
              </html-editor>
            </base-input>
            <validation-error :errors="apiValidationErrors.excerpt" />
          </div>

          <div class="form-group-wrapper-item full">
            <el-checkbox v-model="spotCategory.show_as_filter">{{ $t('CONFIGURATION.SHOW_AS_FILTER') }}</el-checkbox>
          </div>
        </div>
      </div>
      <div class="submit-wrapper">
        <base-button
          class="btn cancel"
          type="button"
          @click="onCloseSpotCategoryModal"
        >
          {{ $t("COMMON.CANCEL") }}
        </base-button>
        <base-button
          type="button"
          class="btn btn-sm elite-submit"
          native-type="submit"
          :disabled="loading || processing"
        >
          <i class="fas fa-spinner fa-spin" v-if="loading || processing" />
          {{ spotCategory.id ? $t("COMMON.EDIT") : $t("COMMON.ADD_ITEM") }}
        </base-button>
      </div>
    </form>
    <modal
      :show="showSpotCategoryCheckModal"
      modal-classes="modal-secondary"
      size="lg"
    >
      <div class="" slot="header">
        <h2 id="">
          {{ $t("COMMON.CONFIRMATION") }}
        </h2>
      </div>
      <div class="">
        <div class="row">
          <p class="text-lg text-center">
            {{
              $t("SPOT_CATEGORIES.SAME_SPOT_CATEGORIES", {
                name: spotCategory.name,
              })
            }}
          </p>
        </div>
        <div class="page-wrapper-table-body">
          <div class="page-wrapper-table-body-inner">
            <el-table
              class="align-items-center table-flush"
              header-row-class-name="thead-light"
              :data="spotCategories"
            >
              <div class="table-loading" slot="empty" v-if="loading">
                <img src="/img/loading.gif" alt="" />
              </div>
              <el-table-column
                :label="$t('COMMON.NAME')"
                min-width="250"
                prop="name"
              >
                <template v-slot="{ row }">
                  <span>
                    {{ row.name }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column min-width="50">
                <div slot-scope="{ row }" class="table-actions">
                  <el-tooltip
                    :content="$t('COMMON.VIEW')"
                    placement="top"
                    v-if="
                      $currentUserCan($permissions.PERM_VIEW_SPOT_CATEGORIES)
                    "
                  >
                    <a
                      type="text"
                      class="table-action"
                      data-toggle="tooltip"
                      style="cursor: pointer"
                    >
                      <router-link :to="$objectViewRoute(row)">
                        <i class="fal fa-expand-alt"></i>
                      </router-link>
                    </a>
                  </el-tooltip>
                </div>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <template slot="footer">
        <button
          type="submit"
          class="btn btn-primary"
          @click="continueFormSubmitProcess"
          :disabled="loading || processing"
        >
          <span v-if="loading || processing" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          {{ $t("COMMON.CONTINUE") }}
        </button>

        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="toggleSpotCategoryCheckModalState"
          :disabled="loading || processing"
        >
          {{ $t("COMMON.CANCEL") }}
        </button>
      </template>
    </modal>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import {Checkbox, Option, Select, Table, TableColumn} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import ImageSelector from "@/components/ImageSelector.vue";
import "flatpickr/dist/flatpickr.css";
import { FORM_TYPES } from "@/constants/common";
import { bookingFormTypeOptions } from "@/constants/spotCategories";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    HtmlEditor,
    ImageSelector,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Checkbox.name]: Checkbox,
  },

  mixins: [formMixin],

  props: [
    "spotCategoryData",
    "formErrors",
    "loading",
    "hideSpotCategoryable",
    "hideSubmit",
  ],

  data() {
    let spotCategoryData = { ...this.spotCategoryData };
    return {
      spotCategory: spotCategoryData,
      spotCategories: [],
      total: 0,
      showSpotCategoryCheckModal: false,
      processing: false,
      FORM_TYPES,
      bookingFormTypeOptions,
    };
  },

  created() {},

  mounted() {},

  computed: {},

  methods: {
    async handleSubmit() {
      const checkUniqueSpotCategory =
        await this.checkSpotCategoryUniqueInformation();
      if (checkUniqueSpotCategory) {
        await this.sendInformation();
      } else {
        this.showSpotCategoryCheckModal = true;
      }
    },

    async sendInformation() {
      let spotCategoryData = cloneDeep(this.spotCategory);

      this.$emit("spotCategorySubmitted", spotCategoryData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },

    async getList() {
      if (!this.spotCategory?.firstname || !this.spotCategory?.lastname) {
        return;
      }
      try {
        this.processing = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...{
              search: `${this.spotCategory?.firstname} ${this.spotCategory?.lastname}`,
            },
          },
          page: {
            number: 1,
            size: 999,
          },
          include: "",
        };

        await this.$store.dispatch("spotCategories/list", params);
        this.spotCategories = this.$store.getters["spotCategories/list"];
        this.total = this.$store.getters["spotCategories/listTotal"];
        this.processing = false;
      } catch (error) {
        this.processing = false;
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async checkSpotCategoryUniqueInformation() {
      await this.getList();
      switch (this.total) {
        case 0:
          return true;
        case 1:
          return !!this.spotCategory?.id;
        default:
          return false;
      }
    },

    async continueFormSubmitProcess() {
      this.processing = true;
      await this.toggleSpotCategoryCheckModalState();
      await this.sendInformation();
    },

    async toggleSpotCategoryCheckModalState() {
      this.showSpotCategoryCheckModal = !this.showSpotCategoryCheckModal;
    },

    onCloseSpotCategoryModal() {
      this.$emit("onCloseSpotCategoryModal");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    spotCategoryData(spotCategoryData) {
      if (spotCategoryData) {
        this.spotCategory = {
          ...this.spotCategory,
          ...cloneDeep(spotCategoryData),
        };
      }
    },
  },
};
</script>

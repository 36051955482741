export default {
  type: "spot-categories",
  name: null,
  excerpt: "",
  color: null,
  icon: null,
  form_type: "DEFAULT",
  booking_form_type: "DEFAULT",
  created_at: null,
  relationshipNames: ["parent"],
  show_as_filter: false,
};
